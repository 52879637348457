.s-intro-hero .container {
  position: relative;
}

.s-intro-hero__body {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.s-intro-hero__pre-title {
  margin-bottom: 2rem;
  font-family: InterMedium;
  line-height: 1.5rem;
  letter-spacing: 0.00625em;
}

.s-intro-hero__title {
  font-size: 7.5rem;
  line-height: 100%;
  letter-spacing: -0.02em;
}

.s-intro-hero__title__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.25rem;
}

.s-intro-hero__title__highlight--indented {
  margin-left: 7.25rem;
  display: inline-block;
}

.s-intro-hero__title__highlight span {
  font-family: InterRegularItalic;
}

.s-intro-hero__title__highlight .spin {
  font-family: InterRegular;
}

.s-intro-hero__title__description {
  max-width: 22.8125rem;
  font-size: 1rem;
  line-height: 1.5rem;
  opacity: 0.72;
  letter-spacing: 0.03125em;
}

.s-intro-hero__btn {
  position: absolute;
  top: 0px;
  right: 2.5rem;
}

@media (max-width: 79.9988em) {
  .s-intro-hero__title {
    font-size: 5.5rem;
  }

  .s-intro-hero__title__highlight {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .s-intro-hero__title__description {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}

@media (max-width: 61.9988em) {
  .s-intro-hero__title__body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .s-intro-hero__title__description {
    max-width: -moz-max-content;
    max-width: -webkit-max-content;
    max-width: max-content;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
  }

  .s-intro-hero__btn {
    display: none;
  }
}

@media (max-width: 47.9988em) {
  .s-intro-hero__body {
    padding-bottom: 3.5rem;
    padding-top: 1rem;
  }

  .s-intro-hero__pre-title {
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0;
  }

  .s-intro-hero__title {
    left: 4rem;
    font-size: 3.5rem;
    letter-spacing: -0.0267857143em;
  }

  .s-intro-hero__title__body {
    gap: 1rem;
  }

  .s-intro-hero__title__highlight--indented {
    margin-left: 0px;
  }
}

@media (max-width: 23.4375em) {
  .s-intro-hero__title {
    font-size: 2.5rem;
  }
}